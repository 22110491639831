import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'

type Props = Omit<TextFieldProps, 'InputProps'>

export const PasswordTextField = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const [visible, setVisible] = React.useState(false)
    const { type, ...rest } = props

    return (
        <TextField
            type={visible ? 'text' : 'password'}
            autoComplete="new-password"
            ref={ref}
            {...rest}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisible((p) => !p)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="large"
                        >
                            {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
})

PasswordTextField.displayName = 'PasswordTextField'
